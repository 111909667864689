export default angular
  .module('directive.goto', [])

  .directive('goto', function () {
    return {
      controllerAs: 'gotoCtrl',
      link: function (scope, element, attrs) {
        //on récupère l'élément qui affiche l'auteur et l'éditeur et on pose dessus un click handler
        //qui va sur la page d'index liée et un auxiliary click handler qui réagit au clic molette
        //pour ouvrir dans un nouvel onglet/nouvelle fenêtre (déterminé par la config navigateur client)
        const authSpan = element.children('.author.event')[0];

        if (authSpan !== undefined) {
          authSpan.onclick = function (event) {
            event.preventDefault();
            location.href = attrs.authorUrl;
          };
          authSpan.onauxclick = function (event) {
            event.preventDefault();
            if (event.which === 2 || event.which === 3) window.open(attrs.authorUrl);
          };
          authSpan.oncontextmenu = function (event) {
            event.preventDefault();
            if (event.which === 3) return false;
          };
        }

        const publiSpan = element.children('.publisher.event')[0];

        if (publiSpan !== undefined) {
          publiSpan.onclick = function (event) {
            event.preventDefault();
            location.href = attrs.publisherUrl;
          };
          publiSpan.onauxclick = function (event) {
            event.preventDefault();
            if (event.which === 2 || event.which === 3) window.open(attrs.publisherUrl);
          };
          publiSpan.oncontextmenu = function (event) {
            event.preventDefault();
            if (event.which === 3) return false;
          };
        }
      }
    };
  });
