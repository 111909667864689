export default angular
  .module('directive.filters', [])

  .directive('filters', function () {
    return {
      restrict: 'E',
      scope: true,
      require: {
        categoryCtrl: '^^categoryProducts'
      },
      bindToController: {
        idCategory: '<',
        searchQuery: '@?',
        scroll: '<',
        device: '<',
        btnFilters: '<',
        rangeFilters: '<',
        isTransversal: '<',
        genericExclude: '<',
        automaticFilter: '<',
        automaticFilterKey: '<',
        automaticFilterValue: '<',
        priceStep: '<',
        maxFilters: '<',
        rangeText: '@',
        forceShowAll: '<'
      },
      controllerAs: 'filtersCtrl',
      /* @ngInject */
      controller: function ($scope, $element, $injector) {
        if (window.isBot || window.isCache) return;
        const ctrl = this;

        ctrl.$onDestroy = () => {};

        ctrl.$onInit = async () => {
          const response = await import(/* webpackChunkName: "category" */ 'Modules/Category/import/category.js');
          $injector.loadNewModules([response.default.name]);
          const service = $injector.get('ImportCategory');
          service.initFilters(ctrl, $scope, $element);
        };
      }
    };
  });
