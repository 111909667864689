const getImport = () => import(/* webpackChunkName: "authentication" */ 'Modules/Authentication/import/authentication.js');

export default angular
  .module('controllers.authentication', [])

  /* @ngInject */
  .controller('authenticationCtrl', function ($injector) {
    const ctrl = this;
    getImport().then(response => {
      $injector.loadNewModules([response.default.name]);
      const service = $injector.get('ImportAuthenticationService');
      service.initAuthentication(ctrl);
    });
  })

  /* @ngInject */
  .controller('loginController', function ($injector) {
    const ctrl = this;
    getImport().then(response => {
      $injector.loadNewModules([response.default.name]);
      const service = $injector.get('ImportAuthenticationService');
      service.initLoginForm(ctrl);
    });
  })

  /* @ngInject */
  .controller('loginModalCtrl', function (data, device, options, $scope, $injector) {
    const ctrl = this;
    ctrl.data = data;
    ctrl.device = device;
    ctrl.options = options;
    $scope.device = device;

    getImport().then(response => {
      $injector.loadNewModules([response.default.name]);
      const service = $injector.get('ImportAuthenticationService');
      service.initModalLogin(ctrl);
    });
  })

  /* @ngInject */
  .controller('newClientController', function ($scope, $injector) {
    const ctrl = this;
    getImport().then(response => {
      $injector.loadNewModules([response.default.name]);
      const service = $injector.get('ImportAuthenticationService');
      service.initNewClientForm(ctrl, $scope);
    });
  })

  /* @ngInject */
  .controller('newClientWithoutRecognitionController', function ($scope, $injector) {
    const ctrl = this;
    getImport().then(response => {
      $injector.loadNewModules([response.default.name]);
      const service = $injector.get('ImportAuthenticationService');
      service.initNewClientWithoutRecognitionForm(ctrl, $scope);
    });
  })

  /* @ngInject */
  .controller('forgotPasswordModalCtrl', function ($scope, $injector) {
    const ctrl = this;
    getImport().then(response => {
      $injector.loadNewModules([response.default.name]);
      const service = $injector.get('ImportAuthenticationService');
      service.initForgotPassword(ctrl);
    });
  })

  /* @ngInject */
  .controller('accountCreateController', function ($scope, $element, $injector) {
    const ctrl = this;
    ctrl.device = $scope.device;

    /**
    * Setup loadingProfil to wait for the loading of the
    * "hobbies" dropdown on the account creation page
    * Chire specific
    */
    ctrl.loadingProfile = true;
    ctrl.formData = {};

    ctrl.initEmail = value => {
      ctrl.formData.Email = value;
    };

    load();

    async function load() {
      const response = await import(/* webpackChunkName: "account.create" */ 'Modules/Account/import/account.create.js');
      $injector.loadNewModules([response.default.name]);
      const service = $injector.get('ImportAccountCreate');
      service.init(ctrl, $scope, $element);
    }
  })

  /* @ngInject */
  .controller('accountCreateWithoutRecognitionController', function ($scope, $element, $injector) {
    const ctrl = this;
    ctrl.device = $scope.device;

    ctrl.formData = {};

    ctrl.initEmail = value => {
      ctrl.formData.Email = value;
    };

    load();

    async function load() {
      const response = await import(/* webpackChunkName: "account.create.without.recognition" */ 'Modules/Account/import/account.create.without.recognition.js');
      $injector.loadNewModules([response.default.name]);
      const service = $injector.get('ImportAccountCreateWithoutRecognition');
      service.init(ctrl, $scope, $element);
    }
  })

  /* @ngInject */
  .controller('accountValidateController', function ($scope, $element, $injector) {
    const ctrl = this;
    ctrl.device = $scope.device;
    load();
    async function load() {
      const response = await import('Modules/Account/import/account.validate.js');
      $injector.loadNewModules([response.default.name]);
      const service = $injector.get('ImportAccountValidate');
      service.init(ctrl, $scope, $element);
    }
  })

  /* @ngInject */
  .controller('modalCartRecoveryCtrl', function (data) {
    const ctrl = this;
    ctrl.data = data;

    ctrl.restore = function (value) {
      ctrl.showLoader = true;
      ctrl.data.action(value, ctrl.data.lastCart.IDFolder);
    };
  })

  /* @ngInject */
  .controller('externalAuthCtrl', function ($injector) {
    const ctrl = this;

    getImport().then(response => {
      $injector.loadNewModules([response.default.name]);
      const service = $injector.get('ImportAuthenticationService');
      service.initLoginForm(ctrl, false, true, 'OctaveExternalAuthentication');
    });
  });
