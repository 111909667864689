export default angular
  .module('module.category', [])

  /* @ngInject */
  .directive('categoryProducts', function () {
    return {
      restrict: 'A',
      scope: true,
      bindToController: {
        idCategory: '<',
        webAspect: '@',
        currentPage: '<',
        totalProducts: '<',
        nbPerPage: '<',
        maxPage: '<',
        sort: '@',
        sortTitle: '@',
        isFiltered: '<',
        searchQuery: '@?',
        iScrollMaxPages: '<',
        iScrollAuto: '<?',
        iScrollText: '@',
        isMessage: '<',
        device: '<',
        automaticFilter: '<',
        automaticFilterKey: '<',
        automaticFilterValue: '<'
      },
      controllerAs: 'categoryCtrl',
      /* @ngInject */
      controller: function ($scope, $element, $filter, $injector, $window) {
        const ctrl = this;

        // Init filters as it is async
        $filter('discount')(1, 'value');
        $filter('price')(1, 'value');

        ctrl.$onDestroy = () => {};

        $scope.redirectToURL = function (url) {
          $window.location.href = url;
        };

        ctrl.$onInit = async () => {
          if (window.isBot || window.isCache) {
            return;
          }
          ctrl.nbProducts = !ctrl.isFiltered ? ctrl.totalProducts : null;
          const response = await import(/* webpackChunkName: "category" */ 'Modules/Category/import/category.js');
          $injector.loadNewModules([response.default.name]);
          const service = $injector.get('ImportCategory');
          service.initModule(ctrl, $scope, $element);

          if (ctrl.webAspect === 'Jobs') {
            import(/* webpackChunkName: "category-jobs" */ 'Modules/Category/import/category-jobs.less');
          }
        };
      }
    };
  });
